<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="会员详情" class="margin-bottom margin-top-xs"></el-page-header>
		<div>
			<!-- 会员资料 -->
			<el-divider content-position="left">会员资料 <el-button class="btn" type="text" @click="$router.push('/member/edit/'+member.id)">编辑</el-button>
			</el-divider>
			<div class="grid col-4">
				<div class="group">
					<div class="label">真实姓名</div>
					<div class="cont">{{member.real_name}}</div>
				</div>
				<div class="group">
					<div class="label">会员性别</div>
					<div class="cont" v-if="member.gender == 0">女</div>
					<div class="cont" v-if="member.gender == 1">男</div>
				</div>
				<div class="group">
					<div class="label">会员昵称</div>
					<div class="cont flex align-center" v-if="member.nickname">
						<el-image v-if="member.avatar" class="avatar" :src="member.avatar" fit="cover"></el-image>
						{{member.nickname}}
					</div>
					<div class="cont" v-else>
						未绑定微信
					</div>
				</div>
				<div class="group">
					<div class="label">手机号码</div>
					<div class="cont" v-if="member.phone">{{member.phone}}</div>
					<div class="cont" v-else>暂无</div>
				</div>
				<div class="group">
					<div class="label">会员生日</div>
					<div class="cont" v-if="member.birthday">{{member.birthday}}</div>
					<div class="cont" v-else>暂无</div>
				</div>
				<div class="group">
					<div class="label">所在地区</div>
					<div class="cont" v-if="member.province">{{member.province}}{{member.city}}{{member.district}}</div>
					<div class="cont" v-else>暂无</div>
				</div>
				<div class="group flex-sub">
					<div class="label">备注</div>
					<div class="cont" v-if="member.remark">{{member.remark}}</div>
					<div class="cont" v-else>暂无</div>
				</div>
			</div>
			<div class="fg"></div>
			<!-- 访问信息 -->
			<el-divider content-position="left">访问信息</el-divider>
			<div class="grid col-4">
				<div class="group">
					<div class="label">注册时间</div>
					<div class="cont">{{member.created_at}}</div>
				</div>
				<div class="group">
					<div class="label">最近访问</div>
					<div class="cont" v-if="member.last_visite_at">{{member.last_visite_at}}</div>
					<div class="cont" v-else>暂无</div>
				</div>
				<div class="group">
					<div class="label">最近消费</div>
					<div class="cont" v-if="member.last_consume_at">
						{{member.last_consume_at}}
					</div>
					<div class="cont" v-else>暂无</div>
				</div>
			</div>
			<div class="fg"></div>
			<!-- 账户信息 -->
			<el-divider content-position="left">账户信息 <el-button class="btn" type="text" @click="$router.push('/member/account/'+member.id)">账户明细</el-button>
			</el-divider>
			<div class="grid col-4">
				<div class="group">
					<div class="label">账户余额</div>
					<div class="cont">{{member.balance}}</div>
				</div>
				<div class="group">
					<div class="label">累计充值</div>
					<div class="cont">{{member.cumulative_recharge}}</div>
				</div>
				<div class="group">
					<div class="label">累计消费</div>
					<div class="cont">
						{{member.cumulative_consumption}}
					</div>
				</div>
				<div class="group">
					<div class="label">累计赠送</div>
					<div class="cont">
						{{member.cumulative_give}}
					</div>
				</div>
			</div>
			<div class="fg"></div>
			<!-- 持卡详情 -->
			<!-- <el-divider content-position="left">持卡详情 <el-button class="btn" type="text" @click="$router.push('/member/cards/'+member.id)">会员卡</el-button>
			</el-divider>
			<div class="grid col-4">
				<div class="group">
					<div class="label">持有卡片</div>
					<div class="cont">{{member.member_card_count}}</div>
				</div>
				<div class="group">
					<div class="label">失效卡片</div>
					<div class="cont">{{member.invalid_card}}</div>
				</div>
			</div>
			<div class="fg"></div> -->
			<!-- 上门地址 -->
			<el-divider content-position="left">上门地址</el-divider>
			<div class="address-box" v-for="add in member.address" :key="add.address_id">
				<div class="grid col-4">
					<div class="group">
						<div class="label">联系人</div>
						<div class="cont">{{add.contact_name}}</div>
					</div>
					<div class="group">
						<div class="label">联系方式</div>
						<div class="cont">{{add.contact_phone}}</div>
					</div>
					<div class="group" v-if="add.is_default == 1">
						<div class="label">[默认地址]</div>
					</div>
				</div>
				<div class="grid col-1">
					<div class="group flex-sub">
						<div class="label">详细地址</div>
						<div class="cont">{{add.province}}{{add.city}}{{add.country}}{{add.address}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	// 行政区划npm版本
	// import ChinaLocation from "china-location";
	// import list from "china-location/dist/location.json";
	export default {
		data() {
			return {
				rules,
				// 会员信息
				member: {
					id: this.$route.params.id,
				}
			};
		},
		computed: {

		},
		mounted() {
			// 获取当前编辑的技师信息
			this.$api.member.getMember({
				id: this.member.id
			}).then(res => {
				if (res.data.birthday) {
					res.data.birthday = res.data.birthday.substring(0, 10);
				}
				this.member = res.data
			})
		},
		methods: {

		}
	};
</script>
<style scoped lang="scss">
	.grid {
		width: 1200px;
	}

	.group {
		display: flex;
		justify-content: flex-start;
		padding: 10px 0px;

		.label {
			width: 100px;
			color: var(--grey);
		}

		.cont {
			flex: 1;
		}

		.avatar {
			width: 24px;
			height: 24px;
			border-radius: 5px;
		}
	}

	.fg {
		height: 20px;
	}

	.address-box {
		width: 1200px;
		border: 1px solid #eee;
		padding: 5px 20px;
		margin-bottom: 20px;

		.grid {
			width: auto;
		}
	}
</style>
